// src/components/AppBar.js
import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import './AppBar.css';
import Toolbar from '@mui/material/Toolbar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
// import { useTheme } from '../../ThemeContext';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { useSelector, useDispatch } from 'react-redux';
// import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { logoutUser } from '../../features/slices/users/userSlice';
import { setTab } from '../../features/slices/tabs/tabSlice';
import { getList } from '../../features/slices/search/searchSlice';
import SelectInstrument from "../SelectInstrument/SelectInstrument";
import { addInstrument } from "../../features/slices/search/searchSlice";
import Popper from '@mui/material/Popper';
import {SocketContext}  from '../../utils/SocketContext';
import { readNotification } from '../../features/slices/alert/TransitionAlertsSlice';

import logo from '../../assets/img/eliteDelta_logo.png';
import { Button } from '@mui/material';
import FeedBackModal from '../Modal/FeedBackModal';

const TopAppBar = () => {
  
  const dispatch = useDispatch();
  // const { mode, themeToggle } = useTheme();
  const { disconnectSocket } = React.useContext(SocketContext);
  const loginData = useSelector((state) => state.user.loginData);
  const [ anchorEl, setAnchorEl ] = useState(null);
  const [userData, setUserData] = useState({name: "User", pic: ""})
  const currentTab  = useSelector((state) => state.tab.tab);
  const notifications  = useSelector((state) => state.notifications.notification);
  const searchList  = useSelector((state) => state.search.instruments.list);
  const searchFetch = useSelector((state) => state.search.instruments.status);
  const [anchorNotification, setAnchorNotification] = React.useState(null);
  const [feedBoxOpen, setFeedBoxOpen] = React.useState(false)

  React.useEffect(()=>{
    if(loginData){
      if(loginData.name){
        setUserData((u)=> ({...u, name: loginData.name}))
      }
      if(loginData.profile_pic_url){
        setUserData((u)=> ({...u, pic: loginData.profile_pic_url}))
      }
    }
  }, [loginData])

  const handleChange = (event, newValue) => {
    dispatch(setTab(newValue));
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    // Handle logout logic here
    dispatch(logoutUser())
    disconnectSocket();
    handleMenuClose();
  };

  const add_instrument = (instrument) => {
    dispatch(addInstrument(instrument))
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          overflow: 'hidden', // Ensure text stays inside the box
          whiteSpace: 'nowrap',
          // backgroundColor: 'yellow', // Background color for the disclaimer
          padding: '0px',
        }}
      >
        <div className="slide-text">Disclaimer: This platform is currently under development and only for testing purposes, and all the data provided here in not designed for public broadcast and not guaranteed for accuracy. We are not SEBI registered, and the information provided here should not be considered as buy or sell recommendations. Please trade at your own discretion and risk !</div>
      </Box>
      <AppBar position="static">
        <Toolbar>
          <Tooltip sx={{padding: 0}} title="VolEdge">
            <IconButton color="inherit" href='#'>
              <img alt='VolEdge logo' width={60} height={40} src={logo} />
            </IconButton>
          </Tooltip>
          <Box sx={{ width: '50%', m: 'auto 0px auto 10px' }}>
            <Tabs
              value={currentTab}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
              <Tab sx={{p:0}} value="live" label="Live" />
              <Tab sx={{p:0}} value="mwpl" label="MWPL" />
              <Tab sx={{p:0}} value="eve" label="Events" />
              <Tab sx={{p:0}} value="flo" label="Flows" />
              {/* <Tab sx={{p:0}} value="pcr" label="PCR" /> */}
              {/* <Tab value="historic" label="Historical" /> */}
              <Button sx={{m: 'auto 0px auto 10px', height: '60%'}} variant='outlined' size='small' onClick={()=>{setFeedBoxOpen(!feedBoxOpen)}}>Suggestion</Button>
            </Tabs>
          </Box>
          <Box sx={{p:0, display:'flex', justifyContent:'flex-end', width:'100%'}}>
            {
              (currentTab === 'live') ?
              <Box component={'div'} sx={{display: 'flex', p: 0, justifyContent:'flex-end', m: 'auto 10px auto 5px'}}>
                <SelectInstrument width={'25vw'} label={'Search Instrument...'} placeholder={"Add Instrument to price action list..."} loading={searchFetch === 'loading'} list={searchList} getList={(e)=> { dispatch(getList(e)); }} onChange={add_instrument}/>
              </Box>
              :null
            }
            {/* <IconButton color="inherit" onClick={themeToggle}>
              {mode === 'light' ? <Brightness4Icon /> : <Brightness7Icon />}
            </IconButton> */}

            <IconButton color="inherit" onClick={(e)=>{ setAnchorNotification(e.currentTarget); dispatch(readNotification()) }}>
              <NotificationsIcon sx={{marginY: 1, color: notifications.some(c => !c.read) ? 'red': 'white'}}/>
            </IconButton>
    

            <Popper
              placement="bottom-end"
              disablePortal={false}
              open={Boolean(anchorNotification)}
              anchorEl={anchorNotification}
              modifiers={[
                {
                  name: 'flip',
                  enabled: true,
                  options: {
                    altBoundary: false,
                    rootBoundary: 'document',
                    padding: 8,
                    },
                    },
                {
                  name: 'preventOverflow',
                  enabled: true,
                  options: {
                    altAxis: true,
                    altBoundary: true,
                    tether: true,
                    rootBoundary: 'document',
                    padding: 8,
                  },
                  },
              ]}
            >
              <ClickAwayListener onClickAway={()=> setAnchorNotification(null)}>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', position: 'relative', overflow: 'auto', maxHeight: 300, '& ul': { padding: 0 },}} subheader={<li />}>
                  {
                    !notifications.length ? 
                      <ListItem alignItems="flex-start">
                        <ListItemText primary={"Nothing new here..."} />
                      </ListItem> :
                    notifications.map((d, i)=>
                      <>
                        <ListItem alignItems="flex-start">
                          <ListItemText primary={d.message} />
                        </ListItem>
                        {
                          i !== notifications.length -1 ? <Divider variant="middle" component="li" /> : null
                        }
                      </>
                    )
                  }
                </List>
                {/* <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper', marginTop: 2.5, minHeight:'400px', maxHeight:'400px',minWidth:'250px', maxWidth:'250px' }}>
                  {
                    !notifications.length ? <Typography>No new Notifications here...</Typography> :
                    notifications.map((d)=>
                      <Typography>{d.message}</Typography>
                    )
                  }
                </Box> */}

              </ClickAwayListener>
            </Popper>

            <Tooltip title="Account settings">
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleMenuOpen}
              >
                <Avatar alt={userData.name} src={userData.pic} />
                <ArrowDropDownIcon />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleChange(null, 'change_password')}>Change Password</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <FeedBackModal open={feedBoxOpen} handleClose={()=>{setFeedBoxOpen(!feedBoxOpen)}}/>
    </>
  );
};

export default TopAppBar;
