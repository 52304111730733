import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField, IconButton, Box } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { kiteAPI } from "../../../utils/axiosInstance";
import { TreeDataGrid } from 'react-data-grid';
import { sortRows, rowGrouper } from "../../Tables/tableFunctions";
import moment from 'moment';

const columns = [
    {
        key: "c_type",
        resizable: true,
        sortable: true,
        minWidth: 150,
        type: "string",
        name: "Participant",
    },
    {
        key: "name",
        resizable: true,
        sortable: true,
        minWidth: 75,
        type: "string",
        name: "Name"
    },
    {
        key: "net",
        resizable: true,
        sortable: true,
        minWidth: 75,
        type: "number",
        name: "Net"
    },
    {
        key: "n_chg",
        resizable: true,
        sortable: true,
        minWidth: 75,
        type: "number",
        name: "Net Chg."
    },
]

const rowClass = (n) => {
    return ((n && n.clr) ? `textclr-${n.clr}` : null)
  }

export default function Flows() {

    const [availableDates, setAvailableDates] = React.useState([]); // Store available dates from API
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [loading, setLoading] = React.useState(true); // Show loading state during API call
    const [sortColumn, setSortColumn] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [data, setData] = React.useState([]);
    const [expandedGroupIds, setExpandedGroupIds] = React.useState(new Set());
    const [calDates, setCalDates] = React.useState({
        yr: moment().year(),
        month: moment().month()+1
    });
    const [datesLimits, setDateLimits] = React.useState({
        min: null,
        max: null
    });
     // Function to handle arrow button click to change the day and skip weekends
     const changeDay = React.useCallback((days) => {
        const newDate = new Date(selectedDate ?? new Date());
        newDate.setDate(newDate.getDate() + days);

        // Keep skipping weekends
        while (!isDateAvailable(newDate)) {
            if(newDate <= datesLimits.max && newDate >= datesLimits.min) {
                newDate.setDate(newDate.getDate() + (days > 0 ? 1 : -1));
            } else {
                if(newDate < datesLimits.max){
                    const dd = moment(newDate).subtract(10 , 'days').toDate()
                    setCalDates((c) => ({ ...c, "yr": dd.getFullYear(), "month": dd.getMonth() + 1}))
                    return;
                }else if(newDate > datesLimits.min){
                    const dd = moment(newDate).add(10 , 'days').toDate()
                    setCalDates((c) => ({ ...c, "yr": dd.getFullYear(), "month": dd.getMonth() + 1}))
                    return;
                }
                return;
                
            }
            
        }
        setSelectedDate(newDate);
    }, [availableDates, selectedDate, datesLimits]);

    const fetchAvailableDates = async (yr, month) => {
        try {
            // Simulating an API call
            const response = await kiteAPI.get(`/fao-participant/get-dates/oi?year=${yr}&month=${month}`); // Example API endpoint
            if(response.status === 200){
                const dateList = response.data.data
                setAvailableDates(dateList)
                if(selectedDate <= datesLimits.max){
                    if(dateList.length){
                        setSelectedDate(new Date((dateList[0])))
                    }
                }else if(selectedDate >= datesLimits.min){
                    if(dateList.length){
                        setSelectedDate(new Date((dateList[dateList.length -1])))
                    }
                }
                setDateLimits((d)=>({...d, min:moment(dateList[dateList.length -1]).toDate(), max: moment(dateList[0]).toDate()}))
            }else if(!selectedDate){
                setSelectedDate(new Date())
            }
            
        } catch (error) {
            console.error('Error fetching available dates:', error);
        } finally {
            setLoading(false);
        }
    }

    React.useEffect(() => {
        setLoading(true)
        fetchAvailableDates(calDates.yr, calDates.month);
    }, [calDates]);

    const fetchFlowData = async (selectedDate) => {
        try {
            if (selectedDate){
            // Simulating an API call
                const response = await kiteAPI.get(`/fao-participant/get/oi?date=${selectedDate.toISOString().split('T')[0]}`); // Example API endpoint
                if(response.status === 200){
                    const dataList = response.data.data;
                    if (!expandedGroupIds.size){
                        setExpandedGroupIds(new Set(dataList.map((d) => d.c_type)))
                    }
                    setData(dataList);
                }else{
                    setData([]);
                }
            }
        } catch (error) {
            console.error('Error fetching available dates:', error);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        fetchFlowData(selectedDate);
    }, [selectedDate]);

    React.useEffect(() => {
        setRows(sortColumn.length ? sortRows(data, sortColumn) : data)
    }, [data, sortColumn]);

    const handleSort = (sortColumns) => {
        setSortColumn([...sortColumns]);
    };

    const isDateAvailable = (date) => {
        date = moment(date).format("YYYY-MM-DD");
        return (availableDates.includes(date));
    };

  return (
    <Box >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box display="flex" justifyContent={'flex-end'} alignItems="center">
                {/* Arrow button to decrement the day */}
                <IconButton onClick={() => changeDay(-1)} size="small">
                    <ArrowBackIosIcon />
                </IconButton>

                    <DatePicker
                        // label="Select a date"
                        formatDensity={'dense'}
                        loading={loading}
                        value={selectedDate}
                        sx={{p:1, width:170}}
                        onMonthChange={(m)=>setCalDates((c) => ({ ...c, "yr": m.getFullYear(), "month": m.getMonth() + 1}))}
                        onChange={(d) => setSelectedDate(d)}
                        shouldDisableDate={(d) => !isDateAvailable(d)} // Disable unavailable dates
                        renderInput={() => <TextField disabled={true} />}
                    />

                {/* Arrow button to increment the day */}
                <IconButton onClick={() => changeDay(1)} size="small">
                    <ArrowForwardIosIcon />
                </IconButton>
            </Box>
        </LocalizationProvider>
        <Box sx={{width: '95%', margin: '0px auto'}}>
            <TreeDataGrid style={{height: "auto"}} columns={columns} groupBy={['c_type']} rowGrouper={rowGrouper} rowClass={rowClass} sortColumns={sortColumn} onSortColumnsChange={handleSort} expandedGroupIds={expandedGroupIds} onExpandedGroupIdsChange={setExpandedGroupIds} rows={rows} enableVirtualization={false} />
        </Box>
    </Box>
  )
}