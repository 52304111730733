
import React from 'react'
import LiveData from "../Pages/LiveData/LiveData";
import MWPL from "../Pages/MWPL/MWPL";
import Events from "../Pages/Events/Events";
import Flows from '../Pages/Flows/Flows';
import PCR from '../Pages/PCR/PCR';
import ChangePassword from "../Auth/ChangePassword/ChangePassword";
import { useDispatch, useSelector } from 'react-redux';
import { updateEngagement, updateStartTime, updateEngagementDB } from "../../features/slices/engagement/engagementSlice";

const Home = () => {
  
  const dispatch = useDispatch();
  const currentTab  = useSelector((state) => state.tab.tab);
  const engagementTime  = useSelector((state) => state.engagement.engagementTime);
  const startTime  = useSelector((state) => state.engagement.startTime);

  const handleVisibilityChange = React.useCallback(() => {
    if (document.hidden) {
      dispatch(updateEngagement(engagementTime + ~~(Date.now() - startTime)));
    } else {
      dispatch(updateStartTime())
    }
  }, [startTime]);

  React.useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const sendEngagementData = React.useCallback(() => {
    if(startTime !== null){
      const timeSpent = ~~(Date.now() - startTime);
      dispatch(updateEngagementDB({engagement: ~~((engagementTime + timeSpent)/1000)}));
    }
    
  }, [startTime, engagementTime]);

  React.useEffect(() => {
    window.addEventListener('beforeunload', sendEngagementData);

    return () => {
      window.removeEventListener('beforeunload', sendEngagementData);
    };
  }, [engagementTime]);

  return (
    <div style={{marginBottom: 30}}>
      <div style={{display: (currentTab === 'live') ? 'block' : 'none' }}>
        <LiveData />
      </div>
      {
        (currentTab === 'change_password') ? <ChangePassword /> : (currentTab === 'mwpl') ? <MWPL /> : (currentTab === 'eve') ? <Events /> : (currentTab === 'flo') ? <Flows /> : (currentTab === 'pcr') ? <PCR /> : null
      }
    </div>
  )
}

export default Home;