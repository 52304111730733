import React from 'react'
import './LiveDataTable.css';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, useGridApiRef} from '@mui/x-data-grid';
import { Box, Select, MenuItem, FormControl, Typography } from '@mui/material';
import { emitDataSocket } from "../../features/slices/socket/socketSlice";
import { updateChain, updateChart } from "../../features/slices/table/tablePrefrenceSlice";
import {SocketContext}  from '../../utils/SocketContext';
import { getExpList, clearExpList } from "../../features/slices/search/searchSlice";
import _ from 'lodash';
import moment from 'moment-timezone';
import {getSortComparator} from "./dateComparator";
import StackedLineChartSharpIcon from '@mui/icons-material/StackedLineChartSharp';

const today = new Date();

const ExpirySelect = React.memo(({value="", id, pref_type, type }) => {
  const dispatch = useDispatch();
  const { socket } = React.useContext(SocketContext);
  const expList  = useSelector((state) => state.search.indicesExp.list);
  const [instExp, setInstExp] = React.useState({})

  const handleOpenExp = (instrument) => {
    setInstExp(instrument)
    dispatch(getExpList(instrument));
  };
  const handleCloseExp = () => {
    dispatch(clearExpList()); 
    setInstExp("");
  };

  const update_instrument_expiry = (event) => {
    const req = {
      "event": "update_preferences",
      "pref_type": pref_type,
      "type": type,
      "instrument": id,
      "expiry": event.target.value
    }
    dispatch(emitDataSocket({ req, socket}))
  }

  return (
    <FormControl variant="standard" fullWidth focused={false} >
      <Select value={value} autoWidth onChange={update_instrument_expiry} open={instExp === id } onOpen={() => handleOpenExp(id)} onClose={handleCloseExp}>
        {
          (instExp === id && expList.length ? expList : [value]).map((d,i)=>
            <MenuItem key={`exp_sel_${i}`} value={d}>{d}</MenuItem>
          )
        }
      </Select>
    </FormControl>
  )
} , (prevProps, nextProps) => {
  return prevProps.value === nextProps.value &&( prevProps.options && nextProps.options && prevProps.options.length === nextProps.options.length);
})

const LiveDataTable = ({pref, updateThrottleMs=1000, setDeleteOptions, setSelectedItems, columnVisibilityModel, resultData = {}}) => {

  const dispatch = useDispatch();
  const { socket } = React.useContext(SocketContext);
  const gridRef = useGridApiRef();
  const [hideFooter, setHideFooter] = React.useState(true)
  const updateInterval  = useSelector((state) => state.socket.updateInterval);
  const [columnVisibilityModelDef, setColumnVisibilityModelDef] = React.useState({});

  const throttledSetRows = React.useCallback(
    _.throttle((newRows) => {
      try{
        gridRef.current.setRows(newRows)
        if (newRows.length > pref.pageSize){
          setHideFooter(false)
        }else{
          setHideFooter(true)
        }
      }catch(e){
        console.warn("Grid ref error...");
      }
    }, updateThrottleMs),
    []
  );

  const openChart = (instrument, expiry, change, price, iv, type) => {
    dispatch(updateChart({ instrument, expiry, change, price, iv, type }));
  }

  const handleCellDoubleClick = (e) => {
    if(e.field === 'portfolio'){
      show_chain(e.id, e.row.expiry)
    }
    if(e.field === 'price'){
      openChart(e.id, e.row.expiry, e.row.price_change, e.row.price, e.row.iv, "C");
    }
    if(e.field === 'fut_price'){
      openChart(e.id, e.row.expiry, e.row.price_change, e.row.price, e.row.iv, "F");
    }
  };

  const memoizedColumns = React.useMemo(() => {
    if (pref && pref.column){
      
      return pref.column.map(d => {
        const ob = 
        { 
          field: d.key, 
          flex: d.width ? false : true,
          width: d.width,
          headerName: d.header,
          cellClassName: d.cellClassName ?? "",
          // flex: true,
          minWidth: d.minWidth,
          maxWidth: d.maxWidth,
          description: d.description ?? d.header ?? '', 
          align: 'center',
          headerAlign: 'center',
          type: d.type ?? 'string',
        }

        if(d.key === 'expiry' && d.type === 'select'){
          ob['renderCell'] = (p) => <ExpirySelect id={p.id} type={pref.type} pref_type={pref.pref_type} value={p.formattedValue} />
        }
        else if(d.key === 'price' ){
          ob['renderCell'] = (p) => <div title='Double click to open chart !' style={{display: 'flex', justifyContent: 'space-around', cursor: 'pointer'}}><Typography>{p.formattedValue}</Typography> <StackedLineChartSharpIcon sx={{color: 'white'}} onClick={() => handleCellDoubleClick(p)} /></div>
        }
        else if(d.key === 'fut_price' ){
          ob['renderCell'] = (p) => <div title='Double click to open chart !' style={{display: 'flex', justifyContent: 'space-around', cursor: 'pointer'}}><Typography>{p.formattedValue}</Typography> <StackedLineChartSharpIcon sx={{color: 'white'}} onClick={() => handleCellDoubleClick(p)} /></div>
        }
        else if(d.key === 'res_date'){
          ob['getSortComparator'] = getSortComparator
        }

        return ob
      })
    }
  }, [pref, resultData]);

  React.useEffect(()=>{
    if(socket){
      socket.on(pref.socketEventName, (rd) => {
        throttledSetRows(rd.map((d)=>({...d, "res_date": resultData[d.id] ? resultData[d.id]: null})))
      })
      socket.emit('g_data', pref.type)
    }
    const interval = setInterval(() => {

      if (document.visibilityState === 'visible') {
        const now = moment().tz("Asia/Kolkata");
        const dayOfWeek = now.isoWeekday(); // 1: Monday, ..., 7: Sunday
        const start = moment().tz("Asia/Kolkata").set({ hour: 9, minute: 0, second: 0 });
        const end = moment().tz("Asia/Kolkata").set({ hour: 15, minute: 30, second: 0 });
        
        if ( socket && dayOfWeek >= 1 && dayOfWeek <= 5 && now.isBetween(start, end)) {
          socket.emit('g_data', pref.type)
        }
      }
    }, 1000*updateInterval); // Update count every second
    return () =>{
      if(socket)
        socket.off(pref.socketEventName);
      clearInterval(interval);
    }
  // eslint-disable-next-line
  }, [socket, pref, updateInterval, resultData])

  const show_chain = (instrument, expiry) => {
    dispatch(updateChain({
        "instrument": instrument,
        "expiry": expiry
      }))
  }

  // const openChart = (instrument, expiry, change, price, iv) => {
  //   dispatch(updateChart({ instrument, expiry, change, price, iv }));
  // }

  const onRowSelectionModelChange = (params) => {
    setDeleteOptions((o)=>{
      const obj = {...o}

      if (!params.length){
        delete obj[pref.type]
      }else{
        obj[pref.type] = params
      }
      return obj

    })

   setSelectedItems([...params])
  }

  return (
    <Box sx={{width: '95%', padding: '1rem', margin: '0px auto', position: 'relative'}}>
      <Box width={'100%'} justifyContent={'center'} display={'flex'}>
        <Typography sx={{cursor:'default'}} fontSize={pref.titleFontSize} fontWeight={700} fontFamily={'emoji'}  color={pref.titleFontColor}>{pref.title}</Typography>
      </Box>
      <DataGrid
        // className={`textclr-${pref.color}`}
        apiRef={gridRef}
        initialState={{
          pagination: { paginationModel: { pageSize: pref.pageSize } },
        }}
        sx={{fontSize: pref?.fontSize || '1.2rem', fontWeight: pref?.fontWeight || 400, margin: '0px auto', '.MuiDataGrid-virtualScroller': {
            overflowY: 'auto !important',
            // example of maxHeight
            maxHeight: pref.height,
            minHeight: 100,
          },
          '.MuiDataGrid-iconButtonContainer': {
            width: '0px !important'
          },
          '.MuiDataGrid-sortIcon': {
            opacity: 'inherit !important',
          },
        }} 
        rowHeight={pref?.RowHeight || 25} 
        columnHeaderHeight={ pref?.columnHeight || 25}
        columns={memoizedColumns} columnVisibilityModel={columnVisibilityModel ?? columnVisibilityModelDef}
        onColumnVisibilityModelChange={!columnVisibilityModel ? setColumnVisibilityModelDef : null}
        onCellDoubleClick={handleCellDoubleClick}
        checkboxSelection
        onRowSelectionModelChange={onRowSelectionModelChange}
        getRowClassName={(p => resultData[p.id] ? ((new Date(resultData[p.id])).toDateString() === today.toDateString() ? 'textclr-yellow' : `textclr-${pref.color}`) : `textclr-${pref.color}` )}
        hideFooter={hideFooter}
        pageSizeOptions={[30,50,100]}
      />
    </Box>

  )
}

export default LiveDataTable