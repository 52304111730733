import React from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import { histAPI } from "../../../utils/axiosInstance";
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default () => {
  const [underlying, setUnderlying] = React.useState({ bucket : "", list: [] });
  const [loadingUL, setLoadingUL] = React.useState(false);
  // const [expiry, setExpiry] = React.useState({ value : "", list: [] });
  // const [loadingExp, setLoadingExp] = React.useState(false);

  const getUnderlying = React.useCallback(async(search)=>{
    setLoadingUL(true);
    const data = await histAPI.post('charts/get-options', {
      search: search
    })
    setLoadingUL(false);
    if (data.status === 200 && data.data.success){
      setUnderlying((o)=>({...o, list: data.data.data}))
    }else{
      setUnderlying((o)=>({...o, list: []}))
    }
  }, [])

  // const getUnderlyingExp = React.useCallback(async(bucket)=>{
  //   setLoadingUL(true);
  //   const data = await histAPI.post('charts/get-options', {
  //     bucket
  //   })
  //   setLoadingUL(false);
  //   if (data.status === 200 && data.data.success){
  //     setExpiry((o)=>({...o, list: data.data.data}))
  //   }else{
  //     setExpiry((o)=>({...o, list: []}))
  //   }
  // }, [underlying.bucket])

  // React.useEffect(() => {
  //   if(underlying.bucket){
  //     getUnderlyingExp(underlying.bucket)
  //   }else{
  //     setExpiry((o)=>({...o, list: []}))
  //   }
  // }, [underlying.bucket])

  return (
    <Box >
      <Box display={'flex'} justifyContent={'space-evenly'}>
        <Autocomplete
          clearOnEscape
          clearOnBlur
          size='small'
          onChange={(e, v) => setUnderlying((o)=> ({...o, bucket: v}))}
          loading={loadingUL}
          loadingText={`Just a sec...`}
          options={underlying.list}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField onChange={(e) => getUnderlying(e.target.value) } placeholder={'Select underlying...'} {...params} />}
        />
        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker sx={{ minHeight: 250 }} label="Start Date" />
          <DatePicker sx={{ minHeight: 250 }} label="End Date" />
        </LocalizationProvider> */}
      </Box>

        <div style={{width: '90vw', height: '90vh', bgcolor: 'burlywood'}}>

        </div>
    </Box>
  )
}           