import React from 'react'
import './OptionChain.css';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Slider, Typography, Stack, FormControl, Select, MenuItem, Switch, Button, TextField } from '@mui/material';
import {SocketContext}  from '../../utils/SocketContext';
import { useSelector, useDispatch } from 'react-redux';
import { getExpList, clearExpList } from "../../features/slices/search/searchSlice";
import { placeOrders } from "../../features/slices/users/userSlice";
import { updateChain } from "../../features/slices/table/tablePrefrenceSlice";
import moment from 'moment-timezone';
import _ from 'lodash';

const chain_local = require('../../jsons/tables/chain_ui.json');

const OptionChain = ({chainInstrument, chainExp, updateThrottleMs=1000}) => {
  
  const dispatch = useDispatch();
  const { socket } = React.useContext(SocketContext);
  const [oneLot, setOneLot] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [showExp, setShowExp] = React.useState(false);
  const [chainData, setChainData] = React.useState({
    lotSize: 0,
    fairPrice: 0,
    iv: 0
  })
  const [opt_Exp, setOpt_Exp] = React.useState([]);
  const [order, setOrder] = React.useState([]);
  const [fut, setFut] = React.useState({});
  const [orderLot, setOrderLot] = React.useState(1);
  const [rowData, setRowData] = React.useState([]);
  const [rowDataFiltered, setRowDataFiltered] = React.useState([]);
  const [selectedColumn, setSelectedColumn] = React.useState([]);
  const expList  = useSelector((state) => state.search.indicesExp.list);
  const [deltaRange, setDeltaRange] = React.useState([chain_local.deltaFilterMin, chain_local.deltaFilterMax]);
  
  const memoizedColumns = React.useMemo(() => {
    return chain_local?.column.map(d => (
      { 
        field: d.key,
        headerName: d.header, 
        description: d.header, 
        // width: d.width ?? 100,
        flex: true,
        minWidth: d.minWidth,
        maxWidth: d.maxWidth,
        headerClassName: ``, 
        align: 'center',
        headerAlign: 'center',
        type: d.type ?? 'string',
      }
    ))
  }, []);

  const throttledSetRows = React.useCallback(
    _.throttle((newRows) => {
      const lotSiz = newRows?.lotSize ?? 0
      setFut(newRows?.futIns ?? {})
      setChainData({...chainData, fairPrice: newRows?.fut_fair_price, iv:newRows?.avgIv, lotSize:lotSiz})
      setRowData(newRows?.chain ?? [])
      if(Array.isArray(newRows?.exp_opt) && opt_Exp.length !== newRows?.exp_opt.length){
        setOpt_Exp(newRows?.exp_opt)
      }
      setLoading(false)
    }, updateThrottleMs),
    []
  );

  const handleOpenExp = () => {
    dispatch(getExpList(chainInstrument));
    setShowExp(true)
  };
  const handleCloseExp = () => {
    setShowExp(false)
    dispatch(clearExpList());
  };

  const update_pref_exp = (event) => {
    dispatch(updateChain({
      "instrument": chainInstrument,
      "expiry": event.target.value
    }))
    socket.emit("update_preferences", {
      "pref_type": "chain",
      "instrument": chainInstrument,
      "expiry": event.target.value
    });
  }

  React.useEffect(()=>{
    if(chainInstrument){
      const filterArr = rowData.filter((d)=> (Math.abs(d.odelta) >=deltaRange[0] && Math.abs(d.odelta) <=deltaRange[1]) )
      if(fut.id){
        filterArr.splice(0,0,fut)
      }
      setRowDataFiltered(filterArr.map((v) => (oneLot ? {
        ...v, "oi": Number.isInteger(v["oi"]) && (v["oi"]/chainData.lotSize), "vega": Math.round(v["vega"]*chainData.lotSize), "theta": v["theta"]*chainData.lotSize, "delval": v["delval"]*chainData.lotSize
      } : v)));
        
    }else{
      setRowDataFiltered([])
    }
  }, [rowData, oneLot, deltaRange, fut] )

  React.useEffect(()=>{
    if(socket){
      if(chainInstrument){
        setLoading(true)
        socket.emit("update_preferences", {
          "pref_type": "chain",
          "instrument": chainInstrument,
          "expiry": chainExp
        });
      }else{
        setLoading(true)
        socket.emit("update_preferences", {
          "pref_type": "dechain"
        });
        setChainData({...chainData, fairPrice:0, iv:0, lotSize:0})
        setOrderLot(1)
        setOrder([])
        setOpt_Exp([])
        setRowData([])
      }
      socket.on(chain_local.socketEventName, (d) => throttledSetRows(d));
    }

    const interval = setInterval(() => {
      const now = moment().tz("Asia/Kolkata");
      const start = moment().tz("Asia/Kolkata").set({ hour: 9, minute: 0, second: 0 });
      const end = moment().tz("Asia/Kolkata").set({ hour: 15, minute: 30, second: 0 });

      if (socket && now.isBetween(start, end) && chainInstrument) {
        socket.emit('g_data', chain_local.type)
      }
    }, 1000); // Update count every second

    
    return () =>{
      if(socket)
        socket.off(chain_local.socketEventName);
      clearInterval(interval);
    }
  // eslint-disable-next-line
  }, [socket, chainInstrument]);

  const RowStyling = (params) => {
    const odelta = Math.abs(params.row.odelta)
    return `textclr-opt-${params.row.color} ${( odelta >= chain_local.deltaFocusMin && odelta <=chain_local.deltaFocusMax) ? "chain-focus" : ""}`
  }

  React.useEffect(()=>{
    const newOrder = selectedColumn.map((d)=>{
      const index = rowDataFiltered.findIndex((v)=> v.id === d)
      // console.log(index);
      if (index !== -1){
        const inst = rowDataFiltered[index]
        
        return {
          "instrument": inst.id,
          "price": inst.price
        }
      }
      return {}
    })
    setOrder([...newOrder]);

  }, [selectedColumn, oneLot]);

  const updatePrice = (e) => {
    const value = e.target.value;
    order[0].price = value;
    setOrder([...order]);
  }

  const placeOdr = (qty) => {
    
    const body = order.map((d)=>{
      return {
        "instrument": d.instrument, 
        "qty": qty, 
        "price": d.price
      }
    })
    dispatch(placeOrders(body))
    setOrder([])
    setSelectedColumn([])
  }

  return (
    <Box sx={{width:'auto', minWidth:'80vw' , px:1}}>
      <Box display={'flex'} width={'100%'}>
        <Box display={'flex'} width={'100%'} justifyContent={'center'}>
          <Typography component={'span'} alignItems='center' color={'#f472b6'} display={'flex'}>{chainInstrument}</Typography>
          <FormControl variant="standard" focused={false} >
            <Select value={chainExp} open={showExp} onOpen={() => handleOpenExp()} onClose={handleCloseExp} onChange={update_pref_exp}>
              {
                (showExp && expList.length ? expList : [chainExp]).map((d, i)=>
                  <MenuItem key={`exp_sel_${i}`} value={d}>{d}</MenuItem>
                )
              }
            </Select>
          </FormControl>
          <Typography component={'span'} marginX={2} alignItems='center' display={'flex'}>FUTPrc: {chainData.fairPrice}</Typography>
          <Typography component={'span'} marginRight={2} alignItems='center' display={'flex'}>IV: {chainData.iv}</Typography>
          <Typography component={'span'} marginRight={2} alignItems='center' display={'flex'}>LotSize: {chainData.lotSize}</Typography>
        </Box>
      </Box>
      <Box sx={{width: '80vw'}}>
        <DataGrid
          loading={loading}
          sx={{fontSize: chain_local?.fontSize || '1.2rem', fontWeight: chain_local?.fontWeight || 400, '.MuiDataGrid-virtualScroller': {
            overflowY: 'auto !important',
            maxHeight: '75vh',
            minHeight: 100,
          } }} 
          rowHeight={chain_local?.RowHeight || 25} 
          columnHeaderHeight={chain_local?.columnHeight || 25}
          onRowSelectionModelChange={(d) => { setSelectedColumn([...d]); }}
          rowSelectionModel={selectedColumn}
          columns={memoizedColumns}
          rows={rowDataFiltered}
          initialState={chain_local.initialState}
          checkboxSelection
          getRowClassName={RowStyling}
          hideFooter
        />
      </Box>
      <Box display={'flex'} width={'100%'}>
        <Box display={'flex'} width={'100%'} justifyContent={'center'}>
          <Box display={'flex'} alignItems={'center'}>
            One Lot: <Switch onChange={(e,d) => setOneLot(d)} checked={oneLot} />
          </Box>
          <Stack sx={{ width:250 }} alignItems={'center'} direction="row">
            <Typography component={'span'} marginRight={2} alignItems='center' display={'flex'}>Delta: </Typography>
            {/* <Typography marginRight={2} alignItems='center' display={'flex'}> {deltaRange[0]}</Typography> */}
            <Slider step={0.01} onChangeCommitted={(e,v)=> setDeltaRange([...v])} valueLabelDisplay={'auto'} value={deltaRange} min={0} max={1} />
            {/* <Typography marginRight={2} alignItems='center' display={'flex'}> {deltaRange[1]}</Typography> */}
          </Stack>
        </Box>
      </Box>
      { 
        order.length > 0 ? 
        <Box display={'flex'} width={'100%'} marginBottom={"0.5rem"}>
          <Box display={'flex'} width={'100%'} justifyContent={"center"}>
            <Button variant="outlined" sx={{color: "#22c55e", mx:"0.5rem" }} onClick={()=> placeOdr(orderLot*chainData.lotSize) } >Buy</Button>
            {
              order.length === 1 ?
              <TextField size='small' sx={{mx: '0.5rem'}} step={0.05} type='number' id="odrPrice" label="Price" variant="outlined" value={order[0].price} onChange={updatePrice} />
              : null
            }
            <TextField size='small' sx={{mx: '0.5rem'}} id="odrLot" type='number' label="Lots" variant="outlined" value={orderLot} onChange={(e)=> setOrderLot(e.target.value) } inputProps={{min:0}} />
            <Button variant="outlined" sx={{color: "#f72b2b", mx:"0.5rem" }} onClick={()=> placeOdr(-1*orderLot*chainData.lotSize) } >Sell</Button>
          </Box>
        </Box> 
        : null
      }
    </Box>
  )
}

export default OptionChain